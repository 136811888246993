import jc from './../jscomponent/jc';

export default jc.component('search', () => {

	let context;
	let $button;

	return {

		init() {
			context = this;
			$button = $('.load-more-results');
			$button
				.off('click')
				.on('click', function (e) {
					e.preventDefault();
					const keyword = $(this).attr('data-keyword');
					const ppr = $(this).attr('data-max');
					context.getContent(keyword, ppr);
				}
			);

			// Accessibility for sorting controls
			$('.access-faux-control', 'div[class*="__sorting-filters"]').on('focus blur', function (e) {
				if (e.type == 'focus') {
					$(this).next('input').next('label').addClass("focus");
				}
				if (e.type == 'blur') {
					$(this).next('input').next('label').removeClass("focus");
				}
			});

			$('.access-faux-control', 'div[class*="__sorting-filters"]').keypress(function (e) {
				if (e.which == 13) {
					e.preventDefault();
					var checked = $(this).next('input').prop('checked') || false;
					$(this).next('input').prop('checked', checked).trigger('click');
				}
			});
		},

		getContent(skeyword, ppr) {
			$.ajax({
				url: window.ajaxurl,
				type: 'post',
				data: {
					keyword: skeyword,
					action: 'get_more_results',
					offset: $('.pod-result').length,
				},
				success(result) {
					const $lastPod = $('.search-result-pod-container > div:last-child');
					$('.search-result-pod-container').append(result);
					let count = $('.pod-result').length;
					$('.search-result-count').html(count);
					$button.blur();
					$lastPod.next().find('.access').focus();
					if (count >= ppr) {
						$button.parent().hide();
						$('.click-for-more-pods').hide();
					}
				},
			});

		},

	}

});
