import jc from './../jscomponent/jc';

export default jc.component('document', () => {
	let context;
	return {

		init () {

			context = this;
			document.documentElement.className = "js";

			const $win = $(window);
			let widthOnLoad = $win.width();
			let cookies = {};
			for (let cookie of document.cookie.split('; ')) {
				let [name, value] = cookie.split("=");
				cookies[name] = decodeURIComponent(value);
			}

			if (!cookies['cookie_message'] || cookies['cookie_message'] === false) {
				$('#mega-footer')
					.addClass('padding-bottom')
					.append($('#cookietext'))
					.find('#cookietext')
					.css({ 'display': 'block' })
					.show();

			} else {
				$('#cookietext').hide();
			}

			$('a[data-cookie-cta]').on('keypress', function (e) {
				var charCode = e.which || e.keyCode;

				if (charCode == 13) {
					context.setCookie(this);
					$('#mega-footer').removeClass('padding-bottom');
				}
			});

			$('a[data-cookie-cta]').on('click', function () {
				context.setCookie(this);
				$('#mega-footer').removeClass('padding-bottom');
			});

			const hideBrowserMessage = target => {
				$('#' + target).fadeOut(function () {
					sessionStorage.setItem('noBrowseMessage', target);
				})
			}

			if (sessionStorage.getItem('noBrowseMessage'))
				hideBrowserMessage(sessionStorage.getItem('noBrowseMessage'))

			$('a[data-dismiss-browser-message]').on('click', function () {
				var target = $(this).attr('data-dismiss-browser-message');
				hideBrowserMessage(target);
			})

			const $backButton = $('.back-top');
			// Go to top sticky button
			const isDesktop = window.innerWidth > 1023;
			const isTablet = (window.innerWidth > 480) && (window.innerWidth < 1024);
			const isMobile = window.innerWidth < 481;

			const $initialHeight = $('body').height() - 100;
			let $opacity = 0;
			$win.scroll(() => {
				$backButton.css('opacity', $win.scrollTop() / $initialHeight);
			});

			$backButton.on('click', e => {
				e.preventDefault();
				$('html, body').animate({ scrollTop: 0 }, 300);
				if ($('.tabfocus.skip').length > 0) {
					$('.tabfocus.skip').eq(0).focus();
				} else {
					$('body').find(':focusable').eq(0).focus();
				}
			})

			$('a', '.wp-posts-carousel-image')
				.focusin(function (e) {
					$(this).parents('.wp-posts-carousel-container').addClass('focused')
				})
				.focusout(function (e) {
					$(this).parents('.wp-posts-carousel-container').removeClass('focused')
				})

			// add accessibility text to links opening in new window
			$('a[target="_blank"]').attr('title', 'Opens in a new window');
		},

		setCookie (dataCTA) {
			var action = $(dataCTA).attr('data-cookie-cta');
			if (action == 'accept') {
				var now = new Date();
				var time = now.getTime();
				var expireTime = time + (365 * 24 * 60 * 60 * 1000);
				now.setTime(expireTime);
				document.cookie = "cookie_message=true; domain=.motability.co.uk" + "; expires=" + now.toGMTString() + "; path=/";
				$('#cookietext').fadeOut();
			}
		},

		delay: (() => {
			var timer = 0;
			return function (callback, ms) {
				clearTimeout(timer);
				timer = setTimeout(callback, ms);
			};
		})(),
	}
});
