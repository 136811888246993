import jc from "./../jscomponent/jc";

class Interval {
	constructor(fn, time) {
		this._timer = false;
		this._fn = fn;
		this._time = time;
	}

	start() {
		if (!this.isRunning())
			this._timer = setInterval(this._fn, this._time);
	}

	stop() {
		clearInterval(this._timer);
		this._timer = false;
	}

	isRunning() {
		return this._timer !== false;
	}
}


export default jc.component("carousel", () => {
	if (wpPostsCarousel == undefined) {
		return;
	}

	let context;
	let stopInterval;
	let owlCarousel = $(wpPostsCarousel);

	// INITIALIZE THE CAROUSEL
	let prevFocus;
	let initialPos;
	let owlLength;
	let mainTabIndex;
	let owlItemFirstIndex;
	let owlData;
	let skipLinkTabindex = 0;
	let owlPlayTabindex = 0;
	let owlPrevTabindex = 0;
	let owlNextTabindex = 0;
	let speed;
	let keyup;

	return {
		init() {
			context = this;
			stopInterval = new Interval(function() {
				owlCarousel.trigger('stop.autoplay.owl')
			}, 200);

			var browser = context.sayswho()[0].toLowerCase() + context.sayswho()[1];
			$('body').addClass(browser);

			$(document).ready(() => {
				this.initializeCarousel();
			});
		},

		initializeCarousel() {
			try {
				owlCarousel.owlCarousel({
					loop: true,
					nav: true,
					navSpeed: 800,
					dots: true,
					dotsSpeed: 800,
					lazyLoad: false,
					autoplay: true,
					autoplayHoverPause: true,
					autoplayTimeout: 3000,
					autoplaySpeed:  800,
					margin: 0,
					stagePadding: 0,
					freeDrag: false,
					mouseDrag: true,
					touchDrag: true,
					slideBy: 1,
					fallbackEasing: "linear",
					responsiveClass: true,
					navText: [ "previous", "next" ],
					responsive:{
						0: {
							items: 1
						},
						600: {
							items: 1
						},
						1000: {
							items: 1
						}
					},
					autoHeight: true,
					onInitialized: (e) => {
						this.onLoadCarousel(e);
					}
				});
			} catch (error) {
				console.warn(error);
			}
		},

		onLoadCarousel(event) {
			try {
				var index = 0;
				prevFocus = jQuery();
				initialPos = owlCarousel.find('.owl-stage').position().left;
				owlLength = owlCarousel.find('.owl-dot').length;
				mainTabIndex = $('.main-content').data('tabindex');
				owlItemFirstIndex = (mainTabIndex - (owlLength + 4));
				owlData = owlCarousel.data('owlCarousel');
				speed = 450;

				if (owlLength <= 1) {
					owlCarousel.find('.owl-controls').hide();
				} else {
					this.addExtraControl();
					$('#main-top-nav a').not('[tabindex=-1]').attr({
						'tabindex': 20
					});
					$('#main-top-nav span[tabindex=0]').attr({
						'tabindex': 20
					});
					$('.skip').attr({
						'tabindex': 20
					});
					$('.global-header a,.global-header input,.global-header button').not('[tabindex="-1"]').attr({
						'tabindex': 1
					});

					owlCarousel.trigger('refresh.owl.carousel');
					this.applyEllipsis($('.wp-posts-carousel-details'));

					$(window).resize(() => {
						initialPos = owlCarousel.find('.owl-stage').position().left;
						owlCarousel.trigger('refresh.owl.carousel');
						// TODO: CHANGE TO PROMISE
						setTimeout(() => {
							this.applyEllipsis($('.wp-posts-carousel-details'));
						}, 100);
					});

					owlCarousel.off('mouseup');
					owlCarousel.off('mousedown');
					owlCarousel.off('mouseover');
					owlCarousel.off('mouseout');
					owlCarousel.off('mouseenter');
					owlCarousel.off('mouseleave');
					owlCarousel.find('.owl-prev').attr({
						'role': 'button',
						'aria-label': 'previous article',
					});
					owlCarousel.find('.owl-next').attr({
						'role': 'button',
						'aria-label': 'next article',
					});
					owlCarousel.find('.owl-dot').attr({
						'tabindex': mainTabIndex,
						'role': 'button',
						'aria-label': 'select slide',
					});
					owlCarousel.find('.owl-item').each((i, element) => {
						var $thisItem = $(element);
						if ($thisItem.hasClass('cloned')) {
							$thisItem.attr('tabindex', '-1');
						} else {
							var $title = $thisItem.find('.wp-posts-carousel-image a').attr('title');
							var tabindex = (index == 0) ? owlItemFirstIndex : '-1';
							$thisItem
								.attr({
									'tabindex': tabindex,
									'title': $title,
									'data-index': index,
								})
							index++
						}
					});

					this.applyTabindex();

					$(document).on('keyup', '.skip-link', (event) => {
						var keyCode = event.keyCode || event.which
						if (keyCode == 13 || keyCode == 32) {
							event.preventDefault();
							this.focusFirstSlide();
						}
					});

					$(document).on('focusout', '.skip-link', (event) => {
						setTimeout(() => {
							if ($(document.activeElement).text() != 'Contact and Support' && keyup == 9) {
								event.preventDefault();
								this.focusFirstSlide();
							}
						}, 100);
					});

					$(document).on('keypress', '.carousel__btn--circle', (event) => {
						var keyCode = event.keyCode || event.which
						if (keyCode == 9 && event.shiftKey) {
							event.preventDefault();
							this.focusFirstSlide();
						}
					});

					$(document).on('keyup', (event) => {
						var keyCode = parseInt(event.keyCode) || parseInt(event.which);
						var activeElement = $(document.activeElement);
						var owlItem = activeElement.hasClass('owl-item');
						var owlPrev = activeElement.hasClass('owl-prev');
						var owlNext = activeElement.hasClass('owl-next');
						var owlDot = activeElement.hasClass('owl-dot');
						var owlPlay = activeElement.hasClass('play');
						var owlStop = activeElement.hasClass('stop');
						var skipLink = activeElement.hasClass('skip-link');
						var btnPlay = $('.owl-playpause .stop').is(':visible') ? true : false;
						keyup = keyCode;

						if (keyCode == 13 || keyCode == 32) {
							event.preventDefault();

							if (owlItem) {
								window.location.href = activeElement.find('a').eq(0).attr('href');
							}
							if (owlDot) {
								var i = activeElement.index();
								owlCarousel.trigger('to.owl.carousel', [i, speed]);
								this.addTabindex(owlCarousel, activeElement);
							}
							if (owlPrev || owlNext) {
								owlCarousel.trigger('refresh.owl.carousel');
								this.addTabindex(owlCarousel, activeElement);
							}
						}
						if (keyCode == 9) {
							if (owlItem || owlPrev || owlNext || owlDot || owlPlay || owlStop) {
								if (btnPlay) {
									if (!stopInterval.isRunning()) {
										stopInterval.start();
									}
								}
							} else {
								if (btnPlay) {
									context.revertTabindex();
									if (stopInterval.isRunning()) {
										stopInterval.stop();
										owlCarousel.trigger('refresh.owl.carousel');
									}
								}
							}
							if (owlItem) {
								context.revertTabindex();
							}
						}

						prevFocus = activeElement;
					}).on('click', function(event) {
						var activeElement = $(document.activeElement);
						var owlItem = activeElement.hasClass('owl-item');
						var owlPrev = activeElement.hasClass('owl-prev');
						var owlNext = activeElement.hasClass('owl-next');
						var owlDot = activeElement.hasClass('owl-dot');
						var owlPlay = activeElement.hasClass('play');
						var owlStop = activeElement.hasClass('stop');
						var btnPlay = $('.owl-playpause .stop').is(':visible') ? true : false;

						if (btnPlay) {
							if (!owlPrev && !owlNext && !owlDot) {
								owlCarousel.find('.owl-item').not('[data-index=0]')
									.attr({
										'tabindex': '-1',
									});
								owlCarousel.find('.owl-item[data-index=0]')
									.attr({
										'tabindex': owlItemFirstIndex,
									});
								if (stopInterval.isRunning()) {
									stopInterval.stop();
									owlCarousel.trigger('refresh.owl.carousel');
								}
							}
						}
						if (owlPrev || owlNext) {
							event.preventDefault();
							this.addTabindex(owlCarousel, activeElement);
						}
						if (owlDot) {
							event.preventDefault();
							this.addTabindex(owlCarousel, activeElement);
						}

						prevFocus = activeElement;
					});

					setTimeout(() => {
						$('#main-top-nav a').not('[tabindex=-1]').attr({
							'tabindex': 20
						});
						$('#virtual-assistant').find("*").each(function(i, el) {
							if (context.focusable(el)) {
								$(el).attr({
									'tabindex': 120
								});
							}
						});
					}, 2000);
				}

				$('.wp-posts-carousel-details').each((i, e) => {
					var $this = $(e);
					var $carousel_image = $this.siblings('.wp-posts-carousel-image');
					var $link = $carousel_image.find('a');
					var $img_src = $carousel_image.find('.featured-img').data('src');

					if (!$img_src) {
						$img_src = $carousel_image.find('.featured-img').attr('src');
					}
					$this.on('click', function (e) {
						e.preventDefault();
						window.location.href = $link.attr('href');
					})
					if ($img_src) {
						if ($img_src.toLowerCase().indexOf("base64") >= 0) {
							$this.addClass('no-image');
						}
					}
				});
			} catch (error) {
				console.warn(error);
			}
		},

		sayswho() {
			var ua = navigator.userAgent;
			var tem;
			var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
			if (/trident/i.test(M[1])) {
				tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
				return 'IE ' + (tem[1] || '');
			}
			if (M[1] === 'Chrome') {
				tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
				if (tem != null) {
					return tem.slice(1).join(' ').replace('OPR', 'Opera');
				}
			}
			M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
			if ((tem = ua.match(/version\/(\d+)/i)) != null) {
				M.splice(1, 1, tem[1]);
			}
			return M;
		},

		// check every focusable element
		focusable(element) {
			var map;
			var mapName;
			var img;
			var nodeName = element.nodeName.toLowerCase();
			var isTabIndexNotNaN = !isNaN($.attr(element, "tabindex"));

			if ("area" === nodeName) {
				map = element.parentNode
				mapName = map.name
				if (!element.href || !mapName || map.nodeName.toLowerCase() !== "map") {
					return false
				}
				img = $("img[usemap=#" + mapName + "]")[0]
				return !!img && context.visible(img)
			}
			return (/input|select|textarea|button|object/.test(nodeName) ?
					!element.disabled :
					"a" === nodeName ?
					element.href || isTabIndexNotNaN :
					isTabIndexNotNaN) &&
				context.visible(element);
		},

		visible(element) {
			return $.expr.filters.visible(element) &&
				!$(element).parents().addBack().filter(function() {
					return $.css(this, "visibility") === "hidden"
				}).length;
		},

		applyTabindex() {
			$('[data-tabindex]').each((i, el) => {
				var $this = $(el);
				var tabindex = $this.data('tabindex');

				$this.find('*').each(function (index, element) {
					var $element = $(element)
					var notFocusable = $element.hasClass('not-focusable') || $element.attr('tabindex') == '-1';
					if (!notFocusable) {
						var owlItem = $element.hasClass('owl-item');
						var owlPlay = $element.hasClass('play');
						var owlStop = $element.hasClass('stop');
						var owlPrev = $element.hasClass('owl-prev');
						var owlNext = $element.hasClass('owl-next');
						var skipLink = $element.hasClass('skip-link')

						if (owlItem || owlPlay || owlStop || owlPrev || owlNext || skipLink) {
							skipLinkTabindex = tabindex - (owlLength + 5)
							owlPlayTabindex = tabindex - (owlLength + 3)
							owlPrevTabindex = tabindex - (owlLength + 2)
							owlNextTabindex = tabindex - (owlLength + 1)

							if (skipLink) {
								$element.attr({
									'tabindex': skipLinkTabindex
								})
							}
							if (owlNext) {
								$element.attr({
									'tabindex': owlNextTabindex
								})
							}
							if (owlPrev) {
								$element.attr({
									'tabindex': owlPrevTabindex
								})
							}
							if (owlPlay || owlStop) {
								$element.attr({
									'tabindex': owlPlayTabindex
								})
							}
						} else {
							if (context.focusable(element) || $element.hasClass("tabfocus")) {
								$element.attr({
									'tabindex': tabindex,
								})
							}
						}
					}
				})
			});
		},

		applyEllipsis($obj) {
			if (typeof $.fn.dotdotdot == "function") {
				$obj.find('.wp-posts-truncate').each(function(i, e) {
					var windowWidth = parseInt($(window).width());
					var height = (windowWidth >= 991) ? 310 : 180;
					$(e)
						.trigger("destroy.dot")
						.dotdotdot({
							watch: 'window',
							height: height,
						})
				});
			}
		},

		reactToControl(e, action, $parent) {
			e.preventDefault();
			var $ctrlEl = $(e.target);

			if (!$ctrlEl.hasClass('play') && !$ctrlEl.hasClass('stop')) {
				return false;
			}

			if (action === 'click') {
				if ($ctrlEl.hasClass('stop')) {
					$ctrlEl.hide();
					$parent.find('.stop').css({
						display: 'none'
					});
					$parent.find('.play').css({
						display: 'block'
					});
					if (!stopInterval.isRunning()) {
						stopInterval.start();
					}
				} else {
					$ctrlEl.hide();
					$parent.find('.stop').css({
						display: 'block'
					});
					$parent.find('.play').css({
						display: 'none'
					});
					if (stopInterval.isRunning()) {
						stopInterval.stop();
						owlCarousel.trigger('play.owl.autoplay', [1000]);
					}
				}
			} else if (action === 'key' && (e.keyCode == 32 || e.keyCode == 13)) {
				if ($ctrlEl.hasClass('stop')) {
					$ctrlEl.hide();
					$parent.find('.stop').css({
						display: 'none'
					});
					$parent.find('.play').css({
						display: 'block'
					}).focus();
					stopInterval.start();
				} else {
					$ctrlEl.hide();
					$parent.find('.stop').css({
						display: 'block'
					}).focus();
					$parent.find('.play').css({
						display: 'none'
					});
					if (stopInterval.isRunning()) {
						stopInterval.stop();
						owlCarousel.trigger('play.owl.autoplay', [1000]);
					}
				}
			}
		},

		addExtraControl() {
			// Attached at the beginning of control from the left
			var child = '<div class="owl-playpause"><div role="button" aria-label="play carousel" tabindex="0" class="carousel__btn--circle secondary play"></div><div role="button" aria-label="stop carousel" tabindex="0" class="carousel__btn--circle secondary stop"></div></div>'
			$(document).find('.owl-nav').prepend(child).on('click', function(e) {
				var $parent = $(this);
				context.reactToControl(e, 'click', $parent);
			}).keyup(function(e) {
				var $parent = $(this);
				context.reactToControl(e, 'key', $parent);
			})
		},

		focusFirstSlide() {
			setTimeout(function() {
				owlCarousel.trigger('to.owl.carousel', [0, 10])
				setTimeout(function() {
					context.revertTabindex();
					stopInterval.start();
					owlCarousel.find('.owl-item.active')
						.focus()
						.attr({
							'tabindex': owlItemFirstIndex,
						})
				}, 300)
			}, 100)
		},

		addTabindex(owlCarousel, activeElement) {
			setTimeout(function() {
				owlCarousel.find('.owl-item.active').attr({
					'tabindex': owlItemFirstIndex,
				})
				owlCarousel.find('.owl-item').not('.active').attr({
					'tabindex': '-1',
				})
				activeElement.attr({
					'tabindex': owlItemFirstIndex - 1,
				})
			}, 200)
		},

		revertTabindex() {
			owlCarousel.find('.owl-item:not(.active)').attr({
				'tabindex': '-1',
			})
			owlCarousel.find('.owl-dot').attr({
				'tabindex': mainTabIndex,
			})
			owlCarousel.find('.owl-prev').attr({
				'tabindex': owlPrevTabindex,
			})
			owlCarousel.find('.owl-next').attr({
				'tabindex': owlNextTabindex,
			})
		},
	};
});
