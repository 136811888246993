import jc from './../jscomponent/jc';

export default jc.component('tag', () => {
	let $sortingButtons;
	let $button;
	let sort;
	let context;

	return {

		init() {
			const $count = $('.tag .site-search__sorting-count');
			$sortingButtons = $('.tag').find('.site-search__sorting-option');
			$button = $('.load-more-tags');
			sort = $('.site-search__sorting-option:checked').val() || "recent";
			const message = "Showing <strong>" + $('.pod-result').length + "</strong> most recent articles";
			$count.html(message);

			$button
				.off('click')
				.on('click', this.fetchMorePodsByTag);

			$sortingButtons.on('change', this.fetchMorePodsByTag);
		},

		fetchMorePodsByTag(e) {
			e.preventDefault();

			let $category = $(e.currentTarget).parent();
			let $count = $('.tag .site-search__sorting-count');
			let sort = $('.site-search__sorting-option:checked').val() || "recent";
			let tag_id = $('.tag-result-pod-container').attr('data-tag');
			let ajax_args = {
				action: 'get_pods_from_tag',
				tag: tag_id,
				sort: sort,
			};
			let isSort = e.type == 'change' ? true : false;
			if (isSort) {
				Object.assign(ajax_args, {
					limit: $('.pod-result').length,
				});
			} else {
				Object.assign(ajax_args, {
					offset: $('.pod-result').length,
				});
			}

			$.ajax({

				url: window.ajaxurl,
				type: 'post',
				data: ajax_args,
				success(result) {

					let message;
					let $lastPod = $('.tag-result-pod-container > div:last-child');

					if (isSort)
						$('.tag-result-pod-container').empty();

					$('.tag-result-pod-container').append(result);
					$lastPod.next().find('.access').focus();

					let podCount = $('.pod-result').length;

					if (sort == "recent") {
						message = "Showing <strong>" + podCount + "</strong> most recent articles";
						$count.html(message);
					} else {
						message = "Showing <strong>" + podCount + "</strong> most popular articles";
						$count.html(message);
					}

					let results_found = $button.attr('data-results-count');
					$(result).each((i, e) => {
						if ($(e).is('span')) {
							if ($(e).hasClass('results-found')) {
								results_found = $(e).attr('data-results-count');
							}
						}
					});

					if (podCount == results_found) {
						$button.parent().hide();
					} else {
						$button.parent().show();
					}

				},

			});

		},

		destroy() {
			$button.off();
		},

	}

});
