import core from "./core";
import Emitter from "es6-event-emitter";
import UtilStorage from "./../util/Storage";

//https://www.npmjs.com/package/es6-event-emitter
const emitterInstance = new Emitter();

export default Object.assign(
	{},
	{ emitter: emitterInstance, storage: UtilStorage },
	core()
);
