import jc from "./../jscomponent/jc";
import Masonry from "masonry-layout";

export default jc.component("pod", () => {
	const ppr = 9;
	let $categories;
	let $categoriesSelect;
	let $categoryCont;
	let $sortingButtons;
	let $button;
	let context;
	let msnry = false;
	let isIE = /*@cc_on!@*/ false || !!document.documentMode;

	return {
		init() {
			$categories = $(".category a");
			$categoriesSelect = $(".categories-mobile");
			$categoryCont = $(".categories");
			$sortingButtons = $(".home, .category").find(".site-search__sorting-option");
			$button = $(".load-more-pods");

			context = this;
			let $count = $(".site-search__sorting-count");
			let sort = $(".site-search__sorting-option:checked").val() || "recent";
			let podCount = $(".pod").length;
			let message;
			let navType = window.hasOwnProperty("performance");

			if (navType && parseInt(window.performance.navigation.type) === 0) {
				if (podCount > 0) {
					sessionStorage.removeItem("category");
				}
			} else {
				if (sort === "popular") {
					let event = JSON.parse(sessionStorage.event);
					context.fetchPods(event);
				}
			}

			if ($(".pods-grid").length > 0) {
				// Initialize Masonry grid
				msnry = new Masonry(".pods-grid", {
					itemSelector: ".pod",
					percentPosition: true,
					//gutter: 15,
					columnWidth: ".pods-grid_sizer",
				});
				// export to global context
				window.msnry = msnry;

				$(window).on("load", () => {
					msnry.layout();
				});
			}

			if ($sortingButtons.length > 0) {
				$sortingButtons.on("change", context.fetchPods);
			}

			if ($count.length > 0) {
				if (sort == "recent") {
					message = `Showing <strong>${podCount}</strong> most recent articles`
					$count.html(message);
				} else {
					message = `Showing <strong>${podCount}</strong> most popular articles`
					$count.html(message);
				}
			}

			$button
				.off('click')
				.on("click", e => {
					context.getContent(e)
				});

			context.podAccessibility();

			if ($categoryCont.length > 0) {
				$categories.on("click", context.fetchPods);
				$categoriesSelect.on("change", context.fetchPods);
			}

			$(document).on(
				"click",
				".pod, .pod-popular, .pod-result, .pod-related",
				(e) => {
					e.stopPropagation();
					const src = $(e.currentTarget).find(".pod_link").attr("href");
					document.location.href = src;
				}
			);

			context.podPopularCount();

			// CUS-7640
			context.preloadCategory();
			if (isIE) {
				context.preloadPopular();
			}

			jc.emitter.on("masonry:render", () => {
				if (msnry) {
					msnry.layout();
				}
			});
		},

		preloadCategory() {
			if (typeof Storage !== "undefined") {
				let category = sessionStorage.category;

				if (category != null) {
					$(".category.category--" + category + " a").trigger(
						"click"
					);
					$(".categories-mobile")
						.find('option[name="' + category + '"]')
						.prop("selected", true);
				}
			}
		},

		preloadPopular() {
			setTimeout(function () {
				if ($(".site-search__sorting-option:checked").val() != "recent")
					$(".site-search__sorting-option:checked").change();
			}, 500);
		},

		podPopularCount() {
			$(".pod-popular").each((i, e) => {
				let num = i + 1;
				$(e)
					.find(".pod__title")
					.prepend(num + ". ");
			});
		},

		podAccessibility() {
			$(document).on("focus", ".pod_link", (event) => {
				$(event.currentTarget).parent().addClass("outlined");
			});

			$(document).on("blur", ".pod_link", (event) => {
				$(event.currentTarget).parent().removeClass("outlined");
			});

			if ($categoryCont.length > 0) {
				$categories.on("click", () => {
					$(".sr-only", ".pods-section").focus();
				});
			}
		},
		getContent(e) {
			e.preventDefault();
			let activeCategory = "";
			let $categoryPage = $(".pods-section--category-page");
			let sort = $(".site-search__sorting-option:checked").val() || "recent";

			if ($categoryPage.length > 0) {
				activeCategory = $categoryPage.data("id");
			} else {
				if ($(".categories-mobile").is(":visible")) {
					activeCategory = $(".categories-mobile")
						.find(":selected")
						.val();
				} else {
					activeCategory = $(".category--active").data("id");
				}
			}

			$.ajax({
				url: window.ajaxurl,
				type: "POST",
				data: {
					action: "get_more_pods",
					category: activeCategory,
					sort: sort,
					offset: $(".pod").length,
				},
				success(result) {
					if (jc.instances.get("carousel")) {
						jc.instances.get("carousel").applyTabindex();
					}
					const $lastPod = $(".pods-grid > div:last-child");
					const grid = document.querySelector(".pods-grid");
					let counter = 0;
					let $count = $(".site-search__sorting-count");
					let results_found = $(".load-more-pods").attr("data-results-count");

					$(result).each((i, e) => {
						if ($(e).is("div")) {
							counter++;
							grid.appendChild(e);
							msnry.appended(e);
							$(e).find('img').on("load", () => {
								msnry.layout();
							});
						} else {
							if ($(e).hasClass("results-found")) {
								results_found = $(e).attr("data-results-count");
							}
						}
					});

					setTimeout(() => {
						$button.blur();
						$lastPod.next().find(".access").focus();
					}, 400);

					let message;
					let podCount = msnry.getItemElements().length;

					if (sort == "recent") {
						message = `Showing <strong>${podCount}</strong> most recent articles`;
						$count.html(message);
					} else {
						message = `Showing <strong>${podCount}</strong> most popular articles`;
						$count.html(message);
					}

					if (podCount == results_found) {
						$button.parent().hide();
						$(".pods-section").addClass("flow");
					}
				},
			});
		},

		fetchPods(event) {
			//event.preventDefault();
			let sessionEvent = {};
			sessionEvent.currentTarget = event.currentTarget;
			sessionStorage.event = JSON.stringify(sessionEvent);
			let $this = $(this);
			let $category = $(event.currentTarget);
			let $count = $(".site-search__sorting-count");
			let reset = false;
			let $categoryPage = $(".pods-section--category-page");
			let id = $category.data("id") || $category.find(":selected").val();
			let sort = $(".site-search__sorting-option:checked").val() || "recent";

			if ($categoryPage.length > 0) {
				id = $categoryPage.data("id");
			} else {
				if ($category.prop("nodeName") == "INPUT") {
					id = $(".category--active").data("id");
				}
			}

			if (id == "default") return;
			if (
				$category.prop("nodeName") !== "SELECT" &&
				$category.hasClass("category--active")
			) {
				id = "all";
				reset = true;
			}

			let isSorting = $(event.currentTarget).attr("name") == "sorting";
			let ajax_pagination = isSorting ? $(".pod").length : 9;

			if (
				isSorting &&
				window.innerWidth < 640 &&
				$("body").hasClass("home")
			) {
				id = $(".categories-mobile").find(":selected").val();
			}

			id = id == undefined ? "all" : id;

			$.ajax({
				url: window.ajaxurl,
				type: "post",
				data: {
					action: "get_pods_from_category",
					category: id,
					sort: sort,
					limit: ajax_pagination,
				},
				success(result) {
					if (jc.instances.get("carousel")) {
						jc.instances.get("carousel").applyTabindex();
					}

					const grid = document.querySelector(".pods-grid");
					let counter = 0;

					// add the active category as location hash
					let catname =
						$category.data("slug") ||
						$category.find(":selected").data("slug") ||
						$(".category--active", ".categories").data("slug");
					let hash =
						catname != undefined && id != "all"
							? "#" + catname
							: " ";

					sessionStorage.category = catname;

					if (reset) {
						$(".category--active").removeClass("category--active");
						$(".category")
							.find('[data-id="all"]')
							.addClass("category--active");
					} else {
						if (
							$category.prop("nodeName") !== "SELECT" &&
							$category.prop("nodeName") !== "INPUT"
						) {
							$(".category--active").removeClass(
								"category--active"
							);
							if (catname == undefined) {
								$(".category")
									.find('[data-id="all"]')
									.addClass("category--active");
							} else {
								$category.addClass("category--active");
							}
						} else if ($category.prop("nodeName") == "SELECT") {
							$category[0].className = "categories-mobile";
							$category[0].className =
								"categories-mobile categories-mobile--" +
								$category.find(":selected").attr("name");
						}
					}

					msnry.remove(document.querySelectorAll(".pod"));
					msnry.layout();

					let results_found = $(".load-more-pods").attr("data-results-count");

					$(result).each((i, e) => {
						if ($(e).is("div")) {
							counter++;
							grid.appendChild(e);
							msnry.appended(e);
							setTimeout(() => {
								msnry.layout();
							}, 2000);
						} else {
							console.log(e);
							if ($(e).hasClass("results-found")) {
								results_found = $(e).attr("data-results-count");
							}
						}
					});

					let message;
					let podCount = msnry.getItemElements().length;

					if (sort == "recent") {

						message = `Showing <strong>${podCount}</strong> most recent articles`;
						$count.html(message);
					} else {
						message = `Showing <strong>${podCount}</strong> most popular articles`;
						$count.html(message);
					}

					console.log("hide", results_found);
					if (podCount >= results_found) {
						$button.parent().hide();
					} else {
						$button.parent().show();
					}
				},
			});
			return false;
		},

		destroy() {
			$button.off();
			$(".pod_link").off();
			$(".pod").off();
		},
	};
});
