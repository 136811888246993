import jc from "./../jscomponent/jc";

export default jc.component("tabfix", () => {
	let context;
	let windowFocus;

	return {
		init() {
			context = this;
			windowFocus = false;
			$(".content-area").on("focus", ".pod-result", context.moveFocus);
			$(".pods-grid").on("focus", ".pod", context.moveFocus);
			$(".popular-section").on(
				"focus",
				".pod-popular",
				context.moveFocus
			);

			window.onfocus = function () {
				windowFocus = true;
			};
		},

		moveFocus(e) {
			if (windowFocus == false) {
				$("html, body").animate(
					{ scrollTop: $(e.target).offset().top },
					"slow"
				);
			}
			windowFocus = false;
		},

		destroy() {
			$(".content-area").off();
			$(".pods-grid").off();
			$(".popular-section").off();
			$(".sd-button").off();
		},
	};
});
