export default {
	getStorage(item, type) {
		let value;
		// Fetch from local storage
		if (type && window.localStorage) {
			value = window.localStorage.getItem(item);
		}
		// Fetch from session storage
		else if (window.sessionStorage) {
			value = window.sessionStorage.getItem(item);
		}
		// Get the cookie
		else {
			this.getCookie(item);
		}
		return value;
	},

	setStorage(item, value, daysToExpiry) {
		if (daysToExpiry && window.localStorage) {
			window.localStorage.setItem(item, value);
		} else if (window.sessionStorage) {
			window.sessionStorage.setItem(item, value);
		} else {
			this.setCookie(item, value, daysToExpiry);
		}
	},

	setCookie(key, value, daysToExpiry) {
		let date, expires;

		if (daysToExpiry) {
			date = new Date();
			date.setTime(date.getTime() + daysToExpiry * 24 * 60 * 60 * 1000);
			expires = "; expires=" + date.toGMTString();
		} else {
			expires = "";
		}
		document.cookie = key + "=" + value + expires + "; path=/";
	},

	getCookie(key) {
		let keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
		return keyValue ? keyValue[2] : null;
	},

	clearCookie(name, domain, path) {
		domain = domain || document.domain;
		path = path || "/";
		document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
	},
};
