export default function core() {

	return {
		// Custom console to comply with linter
		debugger: window.console,
		components: new Map(),
		instances: new Map(),

		component (name, component) {
			this.components.set(name, component);
		},

		startOne (name, value = false) {
			let instance = false;
			if (!value) {
				instance = this.components.get(name)();
			} else {
				instance = value();
			}

			if (instance.hasOwnProperty('init')) {
				instance.init();
				this.instances.set(name, instance);
			} else {
				return false;
			}

			return instance;

		},

		stopOne (name, value = false) {
			let instance = false;
			if (!value) {
				instance = this.components.get(name)();
			}

			if (instance) {
				this.instances.delete(name);
			} else {
				return false;
			}

			return true;

		},

		restartOne (one) {
			this.stopOne(one);
			this.startOne(one);
		},

		resetOne (name, value = false) {
			let instance = false;
			if (!value) {
				instance = this.components.get(name)();
			} else {
				instance = value();
			}

			if (instance.hasOwnProperty('destroy') && instance.hasOwnProperty('init')) {
				instance.destroy();
				instance.init();
				this.instances.set(name, instance);
			} else {
				return false;
			}

			return instance;

		},

		restartAll () {
			this.stopAll();
			this.startAll();
		},

		startAll () {
			this.components.forEach((value, key) => {
				this.startOne(key, value);
			});
		},

		stopAll () {
			this.components.forEach((value, key) => {
				this.stopOne(key, value);
			});
		},

		getComponentByName (name) {
			if (this.components && this.components.has(name)) {
				return this.components.get(name);
			}
		},
	}
}

