/** import external dependencies */
require('dotdotdot-js')
// require('./lib/owl.carousel-2');
require('./lib/owl.carousel');
require('./lib/owlcarousel2-a11ylayer');
require('./lib/jquery.mousewheel')

import jc from "./jscomponent/jc";
import document from "./modules/integrated.document";
import pod from "./modules/pod";
import search from "./modules/search";
import tag from "./modules/tag";
import tabfix from "./modules/tabfix";
import carousel from "./modules/carousel";

jc.startAll();

if (module && module.hot) {
	module.hot.accept();
}
